import PropTypes from "prop-types"
import React from "react"

import Logo from "./Logo"
import Nav from "./Nav"
import Button from "./Button"

const Header = ({ siteTitle }) => (
  <header className="header">
    <div className="container vertical-center">
      <div className="flex vertical-center">
        <Logo />
        <Nav />
        <div id="page-wrap"></div>
      </div>
      <Button text="Kontakta mig" url="/contact" style="primary-btn" />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
