import { Link } from "gatsby"
import React from "react"

const Button = ({ text, url, style }) => (
  <Link
    className={style}
    to={url}
  >
    {text}
  </Link>
)

export default Button
