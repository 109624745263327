import { Link } from "gatsby"
import React from "react"

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <ul className="footer__list">
        <li className="footer__item"><Link to='/home'>Home</Link></li>
        <li className="footer__item"><Link to='/home'>Work</Link></li>
        <li className="footer__item"><Link to='/home'>About</Link></li>
        <li className="footer__item"><Link to='/home'>Blog</Link></li>
        <li className="footer__item"><Link to='/home'>Playlists</Link></li>
      </ul>
      <ul className="footer__list">
        <li className="footer__item"><Link to='/home'>GitHub</Link></li>
        <li className="footer__item"><Link to='/home'>LinkedIn</Link></li>
        <li className="footer__item"><Link to='/home'>Twitter</Link></li>
        <li className="footer__item"><Link to='/home'>Instagram</Link></li>
        <li className="footer__item"><Link to='/home'>Spotify</Link></li>
      </ul>
      <hr className="footer__line"/>
      <div className="row">
        <ul className="footer__list">
            <li className="footer__item footer__terms"><Link to=''>Privacy Policy</Link></li>
            <li className="footer__item footer__terms"><Link to=''>Terms of Use</Link></li>
            <li className="footer__item footer__terms"><Link to=''>Cookies Policy</Link></li>
        </ul>
        <p>Copyright 2020 &copy; Fredrik Johansson</p>
      </div>
    </div>
  </footer>
)

export default Footer
