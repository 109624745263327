import { Link } from "gatsby"
import React from "react"
import { slide as Menu } from "react-burger-menu"

export default props => {
  return (
    <Menu
      {...props}
      customBurgerIcon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="12"
          viewBox="0 0 40 12"
          className="burger"
        >
          <g
            id="Group_6"
            data-name="Group 6"
            transform="translate(-611.5 -199.5)"
          >
            <line
              id="Line_2"
              data-name="Line 2"
              x2="40"
              transform="translate(611.5 200.5)"
              fill="none"
              stroke="#232323"
              stroke-width="2"
            />
            <line
              id="Line_3"
              data-name="Line 3"
              x2="40"
              transform="translate(611.5 210.5)"
              fill="none"
              stroke="#232323"
              stroke-width="2"
            />
          </g>
        </svg>
      }
    >
      <Link to="/work" className="menu-item">
        Work
      </Link>
      <Link to="/about" className="menu-item">
        About
      </Link>
      <Link to="/blog" className="menu-item">
        Blog
      </Link>
      <Link to="/contact" className="menu-item">
        Contact
      </Link>
    </Menu>
  )
}
