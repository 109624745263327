import { Link } from "gatsby"
import React from "react"

const Nav = () => (
  <nav className="nav">
    <ul className="nav__list">
      <li className="nav__item">
        <Link to="/work" className="nav__link">
          Case
        </Link>
      </li>
      <li className="nav__item">
        <Link to="/about" className="nav__link">
          Om mig
        </Link>
      </li>
      <li className="nav__item">
        <Link to="/blog" className="nav__link">
          Blogg
        </Link>
      </li>
    </ul>
  </nav>
)

export default Nav
